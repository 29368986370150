import React, { lazy, Suspense } from "react";
import { Skeleton } from "@mui/material";

const AppRouter = lazy(() => import("./Config/router"));

const App = () => {
  return (
    <Suspense
      fallback={
        <div className="box-center">
          <Skeleton variant="rectangular" width={"100%"} height={60} />
        </div>
      }
    >
      <AppRouter />
    </Suspense>
  );
};

export default App;
